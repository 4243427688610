<template>
  <div class="flex flex-col mb-10">

    <div style="margin-left: -10px; padding:30px; border: 1px solid #8080807a; border-radius: 25px;; border-radius: 5px;">
    
     <div class="md-layout md-gutter">
       <div class="md-layout-item md-size-100 md-xsmall-size-100 md-small-size-100">
        <Content textColor='black' class="my-3 text-uppercase"  text="Number Of Materials" />
       </div>
       
       <div class="md-layout-item md-size-100 md-xsmall-size-100 md-small-size-100" style="padding:30px; border: 1px solid #8080807a; border-radius: 25px;; border-radius: 5px;">
         <div class="md-layout md-gutter">
         <div class="md-layout-item md-size-30 md-xsmall-size-30 md-small-size-30">
          <h4 class="">  {{itemName}} 
            <!-- <span class="rounded bg-victoria text-white font-bold px-2 ">{{count}}</span> -->
            </h4>
        </div>
        <div class="md-layout-item md-size-70 md-xsmall-size-70 md-small-size-70">
          <div class="mb-4 flex justify-end flex-wrap justify-xs-center">
            <md-checkbox  @change="selectAllQuestionId($event)" v-model="form.material_all_access">All Access</md-checkbox>
            <SearchBox
              placeholder="Search"
              class="w-auto p-2 m-1"
              v-model="keyword"
              @submit="submitSearchKeyword"
              />
          </div>
        </div>
         </div>
        <md-divider></md-divider>
         <Table :items="getMaterials" hover-action>

              <template slot="row" slot-scope="{ item }">
                <md-table-cell md-sort-by="id" @click.native="selectQuestionId(item.id)" md-label="SL" md-numeric>{{ item.sl }}</md-table-cell>
                <md-table-cell md-sort-by="qid" @click.native="selectQuestionId(item.id)" md-label="QID" class="text-victoria">{{ item.m_id }}</md-table-cell>
                <md-table-cell md-sort-by="index" @click.native="selectQuestionId(item.id)" md-label="FILE TYPE">{{ item.file_type }}</md-table-cell>
                <md-table-cell md-sort-by="index" @click.native="selectQuestionId(item.id)" md-label="FILE NAME">{{ item.file_name }}
                  <span v-if="form.material_tests.includes(item.id)" class="absolute" style="right:0">
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
                      <path :fill="'#00df05'" d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M17.7,9.7l-7,7C10.5,16.9,10.3,17,10,17s-0.5-0.1-0.7-0.3l-3-3c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l2.3,2.3l6.3-6.3c0.4-0.4,1-0.4,1.4,0S18.1,9.3,17.7,9.7z"  />
                    </svg>
                  </span>
                </md-table-cell>
              </template>
          </Table>
       </div>
     </div>

              
    </div>   

    <div class="flex justify-end mt-4">
      <!-- <md-button class="ml-auto text-uppercase" @click="clearAll">Clear all</md-button> -->
      <Button
          :loading="loading"
          class="bg-victoria rounded text-white mr-0"
          label="Next"
          type="button"
          @click="submit"
      >
      </Button>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Table,
  SearchBox
} from "@/components";

import { mapGetters,mapActions} from 'vuex';
import { required } from "vuelidate/lib/validators";
import Content from "@/components/atom/Content";
import { query } from "@/utils/queryString";
import cms from "@/data/cms";

export default {
  components: {
    Button,
    Content,
    Table,
    SearchBox
  },

  data() {
    return {
      active: false,
      loading: false,
      radio: false,
      courseType: cms.courseType,
      PTEPractice: true,
      PracticeTest: true,
      MockTest: true,
      Quiz: true,
      Materials: true,
      LiveClass: true,
      Webinars: true,
      OneOneAppointment: true,
      SPEAKING: '',
      WRITING: '',
      READING: '',
      LISTENING: '',
      keyword: '',
      selectedKeyword: '',
      count: 0,
      itemName:'',
      tests:[],
      form: { 
        material_all_access: false,
        material_tests:[],
        material_test: '',
        
      } 
      
      
    }
  },
  computed:{
   ...mapGetters({
          getMaterials: "question/getMaterials",
      }),
  },
   watch: {
    keyword(oldvalue, newValue) {
        query.set('file_name', oldvalue)
        // For reseting search result
        if(oldvalue.length < newValue.length && oldvalue.length == 0) {
          this.actGetMaterials(`?${query.get()}`);
        }
        // For submitting search api after 3 cherecter
        if(oldvalue.length >= 3) {
          this.actGetMaterials(`?${query.get()}`);
        }
    }
  },
  methods: {
   
      ...mapActions({
        actGetMaterials: "question/actMaterials",
    }),
    formClear(){
      this.form = {
       
      }
    },
    submitSearchKeyword() {
      this.actGetMaterials(`?${query.get()}`);
      query.remove('section');
      query.remove('question_type_id');
      this.itemName = this.questions?this.questions[0].item:'';
      this.count = this.questions?this.questions.length:0
    },

    selectAllQuestionId(id){
     
      if(id){
        this.getMaterials.map((item) => {
          this.form.material_tests.push(item.id);
          this.tests.push(item.id);
        });
      }else{
         for (let i = 0; i < this.form.material_tests.length; i++) {
          this.tests.indexOf(this.form.material_tests[i]) === -1 ?  '': this.tests.splice(this.tests.indexOf(this.form.material_tests[i]),1);
        }
        this.form.material_tests = [];
      }
    
    },

   selectQuestionId(id){
  
      this.form.material_tests.indexOf(id) === -1 ? this.form.material_tests.push(id): this.form.material_tests.splice(this.form.material_tests.indexOf(id),1);
      this.tests.indexOf(id) === -1 ? this.tests.push(id): this.tests.splice(this.tests.indexOf(id),1);
    }, 
    submit() {
       let formData = this.getLocal('course-create-info');
      this.form.material_test = this.form.material_tests.length;

      if(this.tests.length > 0){
       formData.tests = [...this.tests];
        formData.package_detail = {
          ...formData.package_detail,
          ...this.form
          
        };
      }
      this.setLocal('course-create-info', formData);
      query.remove('file_name');

      this.$emit("menu", this.$route.name);
      this.getPropertiesRoute();
    },

    getPropertiesRoute(){
      let packageInfo = this.getLocal("course-create-info"); 
      if(packageInfo && packageInfo.package_detail){
        
        if(packageInfo.package_detail.is_live_class){
          this.$router.push({ name: 'courseCreation.properties.liveClass' });
          return;
        }
        if(packageInfo.package_detail.is_webinars){
          this.$router.push({ name: 'courseCreation.properties.webinars' });
          return;
        }
        if(packageInfo.package_detail.is_one_to_one_appointment){
          this.$router.push({ name: 'courseCreation.properties.OneToOneAppointment' });
          return;
        }
        this.$router.push({ name: 'courseCreation.durationAndPrice' });
      }
    },
    clearAll() {
      this.$emit("clear-all", "");
    }
  },

  validations: {
    form: {
      type: {required},
     
    }
  },

  created() {

    this.actGetMaterials(``);


    query.remove('section');
    query.remove('question_type_id');

    let existData = this.getLocal('course-create-info');
    if (existData && existData.package_detail && existData.package_detail.material_tests) this.form = existData.package_detail;
    if (existData && existData.questions) this.questions = existData.questions;
    
    
  }
}
</script>